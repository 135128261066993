import React, { FunctionComponent } from 'react';
import { FormGroup } from './form-group';
import { Textarea, TextareaProps } from "./basic/textarea";


export type FormTextareaProps = TextareaProps & {
  name: string,
  required?: boolean,
  label?: string,
  error?: string,
};

export const FormTextarea: FunctionComponent<FormTextareaProps> = ({ required, label, error, name, ...props }) => (
  <FormGroup required={required} name={name} label={label} error={error}>
    <Textarea name={name} disabled={props.disabled} {...props}/>
  </FormGroup>
);
