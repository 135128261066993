import React, { FunctionComponent } from 'react';
import styles from './footer.module.scss';
import ContactForm from './contact-form';
import { Link, useShared } from '@interactivevision/visitor-react';
import { ReactComponent as CompanyLogo } from '../../../../assets/footer-logo.svg';
import { ReactComponent as Facebook } from '../../../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../../../assets/instagram.svg';
import { ReactComponent as Vimeo } from '../../../../assets/vimeo.svg';
import { ReactComponent as Behance } from '../../../../assets/behance.svg';
import { ReactComponent as Fundacja } from '../../../../assets/logo_fundacja.svg';
import { SectionWrapper } from '../../components/wrappers';

export const Footer: FunctionComponent = () => {
  const { menu, socials } = useShared();

  return (
    <footer className={styles.footer}>
      <ContactForm/>

      <SectionWrapper className={styles.lower}>
        <div className={styles.logoWrapper}>
          <CompanyLogo className={styles.logo}/>
        </div>

        <nav className={styles.menu}>
          <div className={styles.title}>Na skróty</div>
          <ul className={styles.links}>
            {menu.bottom?.map((element) => (
              <li key={element.url}>
                <Link href={element.url} rel={element.rel} target={element.target}>
                  {element.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.address}>
          <div className={styles.title}>Dane kontaktowe</div>
          <p>
            Noise Studio <br/>
            35-010 Rzeszów <br/>
            ul. Jałowego 6a/19
          </p>
        </div>

        <div className={styles.contact}>
          <div className={styles.box}>
            <div className={styles.placeholder}>E-mail:</div>
            <a href="mailto:info@noisestudio.pl">info@noisestudio.pl</a>
          </div>
          <div className={styles.box}>
            <div className={styles.placeholder}>Telefon:</div>
            <a href="tel:+48 790 338 033">+48 790 338 033</a>
          </div>
        </div>

        <div className={styles.socials}>
          <a href={socials.facebook} target="_blank" rel="noreferrer" title="Facebook">
            <Facebook/>
          </a>
          <a href={socials.instagram} target="_blank" rel="noreferrer" title="Instagram">
            <Instagram/>
          </a>
          <a href={socials.vimeo} target="_blank" rel="noreferrer" title="Vimeo">
            <Vimeo/>
          </a>
          <a href={socials.behance} target="_blank" rel="noreferrer" title="Behance">
            <Behance/>
          </a>
        </div>
      </SectionWrapper>

      <div className={styles.credits}>
        <SectionWrapper className={styles.creditsWrapper}>
          <span>Realizacja:</span>
          <a href="https://www.fundacjaswiatbezbarier.pl/" target="_blank" rel="noreferrer">
            <Fundacja/>
          </a>
        </SectionWrapper>
      </div>
    </footer>
  );
};
