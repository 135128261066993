import { createElement, ComponentType, useEffect } from 'react';
import { RouterProps } from '@interactivevision/visitor-react';
import { Cookies } from './cookies/components/cookies';

declare var CookieConsentApi: any;

type SetupOptions = {
  router: ComponentType<RouterProps>;
  props: RouterProps;
}

export const app = ({ router, props }: SetupOptions) => {

  useEffect(() => {
    if (CookieConsentApi) {
      CookieConsentApi.updateLanguage(globalThis.LocaleDefault, true);
    }
  }, []);

  return (
    <div>
      {createElement(router, props)}
      <Cookies/>
    </div>
  );
};
