import React, { FunctionComponent, useCallback } from 'react';
import styles from './contact-form.module.scss';
import { SectionWrapper } from "../../components/wrappers";
import { ReactComponent as ArrowRight } from "../../../../assets/arrow-right.svg";
import { Form } from "react-final-form";
import { useSubmitForm } from "../../hooks/use-submit-form";
import { Button } from "../../components/button";
import { ContactModal } from "../../modals/contact-modal";
import { FieldInput } from "../../form/fields/field-input";
import { FieldTextarea } from "../../form/fields/field-textarea";
import { FieldCheckbox } from "../../form/fields/field-checkbox";
import { Link } from "@interactivevision/visitor-react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormApi } from "final-form";

const ContactForm: FunctionComponent = () => {
  const { onSubmit, submitting, showModal, setShowModal } = useSubmitForm();

  return (
    <section className={styles.form} id="kontakt">
      <SectionWrapper className={styles.content}>
        <div className={styles.left}>
          <h2 className={styles.title}>Chcesz dowiedzieć się więcej</h2>
          <div className={styles.fake}>
            <span>Napisz do nas</span>
            <ArrowRight/>
          </div>
        </div>

        <div className={styles.right}>
          <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
            <FormWrapper onSubmit={onSubmit} submitting={submitting}/>
          </GoogleReCaptchaProvider>
        </div>
      </SectionWrapper>

      <ContactModal isOpen={showModal} onClose={() => setShowModal(false)}/>
    </section>
  );
};


export type FormProps = {
  onSubmit: (values: Record<string, any>, api: any) => any;
  submitting: boolean;
}
export const FormWrapper: FunctionComponent<FormProps> = ({ onSubmit, submitting }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async (event: any, form: FormApi) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('yourAction');
    if (token) {
      form.change('token', token);

      form.submit();
    }
  }, [executeRecaptcha]);

  return (
    <Form onSubmit={onSubmit} initialValues={{
      rodo: false,
    }}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FieldInput name="name" label="Imie i nazwisko" required/>
          <FieldInput name="email" label="Email" required/>
          <FieldInput name="phone" label="Telefon" required/>
          <FieldTextarea name="text" label="Wiadomość"/>
          <FieldCheckbox name="rodo">
            Akceptując zgadasz się z <Link href="/regulamin">regulamiem</Link> serwisu.
          </FieldCheckbox>

          <Button disabled={submitting} type="submit" onClick={(event) => handleReCaptchaVerify(event, form)}>Wyślij</Button>
        </form>
      )}/>
  )
}

export default ContactForm;
