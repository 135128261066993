import React, { FunctionComponent } from 'react';
import { FormGroup } from './form-group';
import { Input, InputProps } from "./basic/input";


export type FormInputProps = InputProps & {
  name: string,
  required?: boolean,
  label?: string,
  error?: string,
  switchable?: boolean,
  switched?: boolean,
  handleSwitched?: () => void;
}

export const FormInput: FunctionComponent<FormInputProps> = ({ required, label, error, name, switchable, switched, handleSwitched, ...props }) => {
  return (
    <FormGroup required={required} name={name} label={label} error={error} switchable={switchable} switched={switched} setSwitched={handleSwitched} id={props.id}>
      <Input name={name} {...props} disabled={props.disabled || (switchable && !switched)}/>
    </FormGroup>
  );
};
