import React, { FunctionComponent } from 'react';
import { FormGroup } from './form-group';
import { FormInputProps } from './form-input';
import { Checkbox } from "./basic/checkbox";


export const FormCheckbox: FunctionComponent<FormInputProps> = ({ required, label, error, name, children, id, ...props }) => {
  return (
    <FormGroup required={required} label={label} error={error}>
      <label htmlFor={id || name}>
        <Checkbox name={name} {...props} required={!label && required} id={id}>
          {children}
        </Checkbox>
      </label>
    </FormGroup>
  );
};
