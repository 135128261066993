import { Base } from '../layout/base';


export async function loadAsyncView(name: string) {
  const components = import.meta.glob(`./**/*.tsx`, { eager: false });
  const loader = components[`./${name}.tsx`];

  if (typeof loader === 'undefined') {
    throw new Error(`View "/views/${name}.tsx" was not found!`);
  }

  return resolveDefaultLayout(name, await loader());
}


export async function loadSyncView(name: string) {
  const pages = import.meta.glob('./**/*.tsx', { eager: true });
  const component: any = pages[`./${name}.tsx`];

  if (typeof component === 'undefined') {
    throw new Error(`View "/views/${name}.tsx" was not found!`);
  }

  return resolveDefaultLayout(name, component);
}


function resolveDefaultLayout(name: string, component: any) {
  component.default.layout = component.default.layout || Base;
  return component;
}
