import { readCookie } from "./read-cookie";

export type RejectXMLRequest = {
  status: number;
  message: string;
  errors: Record<string, string>;
}

type methodType = 'GET' | 'POST' | 'DELETE' | 'PATCH'

export async function submitForm(values: Record<string, string>) {
  const formData = new FormData();

  const headers = {
    'X-XSRF-TOKEN': readCookie('XSRF-TOKEN'),
  }

  Object.keys(values).forEach(key => formData.append(key, values[key]));

  return send('/kontakt', 'POST', headers, formData);
}

export default function send(url: string, method: methodType, headers: Record<string, string>, data?: FormData): Promise<any> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(method, url, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]));

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve({ status: xhr.status, data: xhr.response });
      }

      if (xhr.readyState !== XMLHttpRequest.DONE || !xhr.status) {
        return;
      }

      if ((xhr.status >= 400 && xhr.status < 500) || (xhr.status >= 500 && xhr.status < 600)) {
        reject({ status: xhr.status, message: xhr.statusText, errors: xhr.response.errors });
      }
    };

    if (data) {
      xhr.send(data);
    } else {
      xhr.send();
    }
  });
}
