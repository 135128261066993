import React, { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { FormInputProps } from '../form-input';
import { FormCheckbox } from '../form-checkbox';


export const FieldCheckbox: FunctionComponent<FormInputProps> = ({ name, label, switchable, ...props }) => {
  return (
    <Field name={name} type="checkbox" value={props.value} parse={x => x}>
      {({ input, meta }) => (
        <FormCheckbox label={label} {...props} {...input} error={meta.submitError}/>
      )}
    </Field>
  );
};
