import { Navbar } from './components/navbar';
import { Footer } from './components/footer';
import { route } from '@interactivevision/routing';
import { useLocation } from '@interactivevision/visitor-react';
import styles from './base.module.scss';
import classNames from 'classnames';
import LogoEu from '../../../../public/static/logotypes/logo-eu.jpg';
import LogoFefr from '../../../../public/static/logotypes/logo-fefr.jpg';
import LogoPl from '../../../../public/static/logotypes/logo-pl.jpg';
import LogoPpo from '../../../../public/static/logotypes/logo-ppo.jpg';
import Background from '../../../../public/static/szum.png';
import { useEffect } from 'react';

export function Base({ children }: any) {
  const location = useLocation();
  const classes = () => {
    let homepage = route('homepage_module');

    if (homepage[homepage.length - 1] === '/') {
      homepage = homepage.slice(0, homepage.length - 1);
    }

    return homepage !== location;
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.background = `
      url(${Background}),
      linear-gradient(90.22deg, #292148 26.42%, #3a639a 95.22%)
      `;
    }
  }, []);

  return (
    <>
      <Navbar blur={location == route('homepage_module')}/>

      <main className={classNames({ [styles.main]: classes() })}>
        {children}
      </main>
      <Footer/>

      <div className={styles.logotypes}>
        <div className={styles.wrapper}>
          <div>
            <img src={LogoEu} alt=""/>
          </div>
          <div>
            <img src={LogoFefr} alt=""/>
          </div>
          <div>
            <img src={LogoPl} alt=""/>
          </div>
          <div>
            <img src={LogoPpo} alt=""/>
          </div>
        </div>
      </div>
    </>
  );
}
