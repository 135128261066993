import { Base, FontSize, Weight } from "./base";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";

export type SectionTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  weight?: Weight;
  size?: FontSize;
}

const SectionTitle = forwardRef<any, PropsWithChildren<SectionTitleProps>>((props, ref) => {
  return <Base as="h2" {...props} size={props.size || 8} weight={props.weight || 700} ref={ref} className={props.className}>{props.children}</Base>
});

export default SectionTitle;
