import { FunctionComponent, PropsWithChildren } from "react";
import styles from "./modal.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import classNames from "classnames";

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  className?: string;
}
export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({ onClose, title = '', isOpen, children, className }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classNames(styles.modal, className)}>
      <div className={styles.container}>
        <div className={styles.header}>
          {title && <span className={styles.title}>{title}</span>}
          <button className={styles.close} onClick={onClose}>
            <CloseIcon/>
          </button>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  )
}
