import React, { ButtonHTMLAttributes, forwardRef, HTMLAttributes, LinkHTMLAttributes, PropsWithChildren } from "react";
import styles from "./base.module.scss";
import classNames from "classnames";

export type Weight = 300 | 400 | 500 | 600 | 700;
export type FontSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;

export type BaseType = HTMLAttributes<any> & LinkHTMLAttributes<any> & ButtonHTMLAttributes<any> & {
  as?: React.ElementType;
  size: FontSize;
  weight?: Weight;
  style: any;
}

export const Base = forwardRef<any, PropsWithChildren<BaseType>>(({ as, size, style, weight, ...props }, ref) => {
  const Component = as || 'h1';

  return (
    <Component {...props}
      className={classNames(props.className, styles[`size${size}`], { [styles[`weight${weight}`]]: weight })} ref={ref}
      style={style}
    >
      {props.children}
    </Component>
  )
});
