import classNames from 'classnames';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import styles from './form-group.module.scss';
import { FormError } from './form-error';


export type FormGroupProps = {
  id?: string;
  required?: boolean,
  name?: string,
  children?: React.ReactNode,
  label?: string,
  error?: string,
  switchable?: boolean,
  switched?: boolean,
  setSwitched?: () => void,
}

export const FormGroup: FunctionComponent<PropsWithChildren<FormGroupProps>> = (props) => (
  <div className={styles.formGroup}>
    {props.label && (
      <div className={styles.labelWrapper}>
        <label className={classNames(styles.label, { [styles.required]: props.required })} htmlFor={props.id || props.name}>
          {props.label}
        </label>
      </div>
    )}

    {/*Input goes here */}
    {props.children}

    {props.error && (
      <FormError>
        {props.error}
      </FormError>
    )}
  </div>
);
