import classNames from 'classnames';
import React, { FunctionComponent, PropsWithChildren, DetailedHTMLProps, ButtonHTMLAttributes, ReactNode, MouseEventHandler } from 'react';
import styles from './button.module.scss';


export type BaseButtonProps = {
  /** Styling of the button */
  kind?: 'primary' | 'secondary';
  /** Stretches the button to it's maximal width */
  fullWidth?: boolean;
  /** No background */
  outlined?: boolean;
  icon?: React.ReactElement;
  /** Disables the button */
  disabled?: boolean;
  /** Show a circular progress bar */
  loading?: boolean;
  /** Renders the button highlighted */
  className?: string;
};


export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & BaseButtonProps & {
  prompt?: ReactNode;
};

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
                                                                            type = 'button',
                                                                            className,
                                                                            children,
                                                                            disabled = false,
                                                                            onClick,
                                                                            prompt,
                                                                            icon,
                                                                            kind = 'primary',
                                                                            loading = false,
                                                                            outlined = false,
                                                                            fullWidth = false,
                                                                            ...props
                                                                          }) => {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const classes = compileButtonClasses({
    className,
    kind,
    fullWidth,
    outlined,
    icon: !!icon,
  });

  return (
    <button className={classes} disabled={disabled || loading} type={type} onClick={handleOnClick} {...props}>
      {children && (
        <span className={classNames(styles.content, { [styles.invisible]: loading })}>
        {children}
      </span>
      )}
      {icon && icon}
    </button>
  );
};

type ButtonClassCompilerProps = {
  className?: string;
  kind: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'secondary' | 'tertiary';
  fullWidth: boolean;
  outlined: boolean;
  icon: boolean;
};

export function compileButtonClasses(props: ButtonClassCompilerProps) {

  return classNames(
    props.className,
    styles.button,
    styles[props.kind],
    {
      [styles.fullWidth]: props.fullWidth,
      [styles.outlined]: props.outlined,
      [styles.icon]: props.icon,
    },
  );
}
