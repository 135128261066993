import { Modal } from '../components/modal';
import { ReactComponent as EnvelopeIcon } from '../../../assets/envelope.svg';
import styles from './contact-modal.module.scss';
import { FunctionComponent } from 'react';
import Paragraph from "../typography/paragraph";

export const ContactModal: FunctionComponent<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {

  return (
    <Modal onClose={onClose} isOpen={isOpen} title="Dziękujemy" className={styles.modal}>
      <EnvelopeIcon className={styles.icon}/>
      <Paragraph size={12}>
        Otrzymaliśmy twoją wiadomość i wkrótce się z tobą skontaktujemy.
      </Paragraph>
    </Modal>
  );
};
