import React, { FunctionComponent, useState, useEffect } from 'react';
import styles from './navbar.module.scss';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../../../assets/company-logo.svg';
import { ReactComponent as Facebook } from '../../../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../../../assets/instagram.svg';
import { ReactComponent as Vimeo } from '../../../../assets/vimeo.svg';
import { ReactComponent as Behance } from '../../../../assets/behance.svg';
import { Link, useShared } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';

export const Navbar: FunctionComponent<{ blur?: boolean }> = ({ blur = false }) => {
  const [active, setActive] = useState<boolean>(false);
  const { menu, socials } = useShared();
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    if (window) {
      const handleScroll = () => {
        if (window.scrollY > 10) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <nav className={classNames(styles.navbar, { [styles.blur]: blur }, { [styles.scrolled]: scrolled })}>
      <div className={styles.content}>
        <Link href={route('homepage_module')} className={styles.logo}>
          <Logo/>
        </Link>

        <ul className={classNames(styles.menu, { [styles.active]: active })}>
          {menu.main?.map(element => (
            <li key={element.url}>
              <Link href={element.url} rel={element.rel} target={element.target}>
                {element.name}
              </Link>
            </li>
          ))}
          <li className={styles.socials}>
            <a href={socials.facebook} target="_blank" rel="noreferrer" title="Facebook">
              <Facebook/>
            </a>
            <a href={socials.instagram} target="_blank" rel="noreferrer" title="Instagram">
              <Instagram/>
            </a>
            <a href={socials.vimeo} target="_blank" rel="noreferrer" title="Vimeo">
              <Vimeo/>
            </a>
            <a href={socials.behance} target="_blank" rel="noreferrer" title="Behance">
              <Behance/>
            </a>
          </li>
        </ul>

        <div className={styles.socials}>
          <button className={classNames(styles.burger, { [styles.active]: active })} onClick={() => setActive(!active)} aria-label="Menu">
            <span className={classNames(styles.line, styles.line1)}/>
            <span className={classNames(styles.line, styles.line2)}/>
            <span className={classNames(styles.line, styles.line3)}/>
            <span className={classNames(styles.line, styles.line4)}/>
          </button>
        </div>
      </div>
    </nav>
  );
};
