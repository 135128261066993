import React, { FunctionComponent } from 'react';
import styles from './checkbox.module.scss';
import { Input, InputProps } from './input';
import classNames from 'classnames';


export const Checkbox: FunctionComponent<InputProps> = ({ children, required, ...props }) => (
  <div className={classNames(styles.checkbox, { [styles.disabled]: props.disabled })}>
    <Input type="checkbox" {...props} required={required}/>
    <span className={styles.check}/>
    {children && (
      <div className={classNames(styles.label, { [styles.required]: required })}>
        {children}
      </div>
    )}
  </div>
);
