import { createRoot, hydrateRoot } from 'react-dom/client';
import { createElement } from 'react';
import { createVisitor } from '@interactivevision/visitor-react';
import { app } from './app';
import { loadAsyncView } from './views/loader';


createVisitor({

  async resolve(name: string) {
    return loadAsyncView(name);
  },

  setup(options) {
    if (import.meta.hot) {
      createRoot(document.getElementById('app') as HTMLElement).render(createElement(app, options));
    } else {
      hydrateRoot(document.getElementById('app') as HTMLElement, createElement(app, options));
    }
  },

});

