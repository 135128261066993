import { Base, FontSize } from "../base";
import React, { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./article-title.module.scss";

type Props = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
  size?: FontSize;
}

export const ArticleTitle = forwardRef<any, PropsWithChildren<Props>>(({ as = 'h2', size = 8, ...props }, ref) => {
  return (
    <Base as={as}
      {...props} size={size}
      weight={500} ref={ref}
      className={classNames(props.className, styles.title)}
      style={{ "--article-title-font-size": `var(--font-size-${size})` }}
    >
      {props.children}
    </Base>
  )
});
